import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Dialog,
  DialogContent,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { colors } from "../../muiTheme";
import { userState } from "../../recoil/atom";
import DialogTitleWithClose from "../core/DialogTitleWithClose";

export const LabelBankForm = styled(Typography)({
  fontSize: "10px",
  color: colors.whiteGrey,
});

export const ValueBankForm = styled(Typography)({
  fontSize: "16px",
  lineHeight: "24px",
  color: colors.white,
});

interface BankAccountDialogProps {
  open: boolean;
  onClose: () => void;
}
const BankAccountDialog: FunctionComponent<BankAccountDialogProps> = ({
  open,
  onClose,
}) => {
  const user = useRecoilValue(userState);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: colors.blueDarkPrimary,
          boxShadow: "none",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitleWithClose onClose={onClose}>
        Bank Account
      </DialogTitleWithClose>
      <DialogContent>
        <Alert
          severity="warning"
          icon={<ErrorOutlineIcon sx={{ color: colors.yellowDarkPrimary }} />}
          sx={{
            background: colors.whiteBackground,
            color: colors.yellowDarkPrimary,
            fontSize: "14px",
            lineHeight: "20px",
            mb: 3,
          }}
        >
          If you want to change bank account, please contact
          velo.support@velovelo.com
        </Alert>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabelBankForm>Bank</LabelBankForm>
            <ValueBankForm>{user?.bank_name || "-"}</ValueBankForm>
          </Grid>
          <Grid item xs={12}>
            <LabelBankForm>Account Number</LabelBankForm>
            <ValueBankForm>{user?.bank_account || "-"}</ValueBankForm>
          </Grid>
          <Grid item xs={12}>
            <LabelBankForm>Account Name (optional)</LabelBankForm>
            <ValueBankForm>{user?.name || "-"}</ValueBankForm>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BankAccountDialog;

import { Button, Menu, MenuItem, styled } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { colors } from "../../muiTheme";
import { userState } from "../../recoil/atom";
import { truncate } from "../../util/common";
import BankAccountDialog from "../BankAccountDialog";

const MainButton = styled(Button)({
  fontSize: "15px",
  background: colors.yellowDarkPrimary,
  border: colors.boderYellow,
  color: colors.blueDarkPrimary,
  padding: "6px 16px",
  mx: 2,

  "&.MuiButton-root:hover": {
    border: colors.boderYellow,
    background: colors.yellowDarkPrimary,
    color: colors.blueDarkPrimary,
  },
});

const MenuUser: FunctionComponent = () => {
  const user = useRecoilValue(userState);
  const resetUserState = useResetRecoilState(userState);

  const [openBankAccountDialog, setOpenBankAccountDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickBankAccount = () => {
    setOpenBankAccountDialog(true);
  };

  const onCloseBankAccountDialog = () => {
    setOpenBankAccountDialog(false);
  };

  const onClickLogout = () => {
    resetUserState();
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("jwtToken");
  };

  return (
    <>
      <MainButton disableRipple onClick={handleClick}>
        {truncate(user?.wallet_address || "", 12)}
      </MainButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            background: colors.blueDarkPrimary,
            color: colors.white,
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
          },
        }}
      >
        <MenuItem onClick={onClickBankAccount}>BANK ACCOUNT</MenuItem>
        <MenuItem onClick={onClickLogout}>SIGN OUT</MenuItem>
      </Menu>

      <BankAccountDialog
        open={openBankAccountDialog}
        onClose={onCloseBankAccountDialog}
      ></BankAccountDialog>
    </>
  );
};

export default MenuUser;

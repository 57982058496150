import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getBankInformtaion, putProfile } from "../../api/AmtApi";
import { colors } from "../../muiTheme";
import {
  loadingState,
  toastContentState,
  toastStateState,
  userState,
} from "../../recoil/atom";
import { BankInformation } from "../../typings/model.api";
import { getErrorMessage, getStatusCode } from "../../util/common";
import { STATUS_CODE } from "../../util/constants";
import { mapProfileRequest } from "../../util/mapper";
import DialogTitleWithClose from "../core/DialogTitleWithClose";
import { ActionButton } from "../RowMerchant";

interface ISubscribeFormInput {
  bankCode: string;
  bankAccount: string;
  name: string;
}

const TextFieldBank = styled(TextField)({
  "& label.Mui-focused": {
    color: colors.whiteGrey,
  },
  "& label.Mui-error": {
    color: colors.red,
  },

  "& .MuiOutlinedInput-root": {
    color: colors.white,
    "&.Mui-focused fieldset": {
      border: colors.boderYellow,
    },
    "&.Mui-error fieldset": {
      border: colors.borderRed,
    },
  },
});

const CustomInput: FunctionComponent<TextFieldProps> = (props) => {
  return <TextFieldBank {...props} />;
};

interface AddBankAccountDialogProps {
  open: boolean;
  onClose: () => void;
}
const AddBankAccountDialog: FunctionComponent<AddBankAccountDialogProps> = ({
  open,
  onClose,
}) => {
  const setUser = useSetRecoilState(userState);
  const setIsToastAlertOpen = useSetRecoilState(toastStateState);
  const setToastContent = useSetRecoilState(toastContentState);
  const [loading, setLoading] = useRecoilState(loadingState);

  const [bankInformation, setBankInformation] = useState<BankInformation[]>([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISubscribeFormInput>({
    defaultValues: {
      bankCode: "",
      bankAccount: "",
      name: "",
    },
  });

  const onSubscribe: SubmitHandler<ISubscribeFormInput> = async (data) => {
    if (validate(data)) {
      //error
    } else {
      try {
        setLoading(true);

        const req = mapProfileRequest(
          data.bankCode,
          data.bankAccount,
          data.name
        );
        const response = await putProfile(req);
        const statusCode = getStatusCode(response);

        if (statusCode !== STATUS_CODE[20000]) throw response;

        setUser(response.data.data);

        setLoading(false);
        onClose();
      } catch (error) {
        setLoading(false);
        setToastContent(getErrorMessage(error));
        setIsToastAlertOpen(true);
      }
    }
  };

  const validate = (data: ISubscribeFormInput) => {
    return !data.bankCode || !data.bankAccount;
  };

  const fetchBankInformation = async () => {
    try {
      setLoading(true);
      const response = await getBankInformtaion();
      const statusCode = getStatusCode(response);

      if (statusCode !== STATUS_CODE[20000]) throw response;

      const { data } = response.data;
      setBankInformation(
        data.filter((bank: BankInformation) => bank.status === "active")
      );

      setLoading(false);
    } catch (error) {
      onClose();
      setToastContent(getErrorMessage(error));
      setIsToastAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchBankInformation();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {!loading && (
        <Dialog
          fullWidth
          maxWidth="xs"
          onClose={onClose}
          open={open}
          PaperProps={{
            style: {
              backgroundColor: colors.blueDarkPrimary,
              boxShadow: "none",
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitleWithClose onClose={onClose}>
            Connect with bank account
            <Typography
              sx={{
                color: colors.whiteGrey,
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              You bank account is used for buying and selling token
            </Typography>
          </DialogTitleWithClose>
          <DialogContent>
            <Alert
              severity="warning"
              icon={
                <ErrorOutlineIcon sx={{ color: colors.yellowDarkPrimary }} />
              }
              sx={{
                background: colors.whiteBackground,
                color: colors.yellowDarkPrimary,
                fontSize: "14px",
                lineHeight: "20px",
                mb: 3,
              }}
            >
              You cannot change bank account later.
            </Alert>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldBank
                  inputProps={register("bankCode", {
                    required: "Required",
                  })}
                  defaultValue=""
                  fullWidth
                  label="Bank"
                  select
                  focused
                  error={!!errors.bankCode}
                  required={false}
                  helperText={!!errors.bankCode ? errors.bankCode.message : ""}
                  sx={{
                    "&:before": {
                      borderColor: colors.boderYellow,
                    },
                    "&:after": {
                      borderColor: colors.boderYellow,
                    },
                    ".MuiSvgIcon-root ": {
                      fill: colors.whiteGrey,
                    },
                  }}
                >
                  {bankInformation.map((b) => (
                    <MenuItem value={b.bank_code} key={b.bank_code}>
                      {b.bank_name}
                    </MenuItem>
                  ))}
                </TextFieldBank>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="bankAccount"
                  rules={{
                    required: "Required",
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                      customInput={CustomInput}
                      autoComplete="off"
                      fullWidth
                      focused
                      label="Bank Account Number"
                      type="tel"
                      allowLeadingZeros
                      allowNegative={false}
                      valueIsNumericString={true}
                      decimalScale={0}
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={!!errors.bankAccount}
                      helperText={
                        !!errors.bankAccount ? errors.bankAccount.message : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldBank
                  {...register("name")}
                  autoComplete="off"
                  fullWidth
                  focused
                  label="Bank Account Name (optional)"
                />
              </Grid>
              <Grid item xs={12}>
                <ActionButton fullWidth onClick={handleSubmit(onSubscribe)}>
                  CONFIRM
                </ActionButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AddBankAccountDialog;

import { FC, useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import bgError from "../../../assets/images/error.svg";
import Poster from "../../../components/core/Poster";
import { t } from "../../../i18n";
import { getFallbackCode } from "../../../util/common";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const [errorCode] = useState(() => getFallbackCode(error));

  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
  }, []);

  return (
    <Poster
      image={bgError}
      info={t.error.errorFallback}
      errorCode={errorCode}
    ></Poster>
  );
};

export default ErrorFallback;

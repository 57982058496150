import { Card, CardMedia, Grid, Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { colors } from "../../../muiTheme";

interface PosterProps {
  info: string;
  image: string;
  errorCode?: string;
}

const Poster: FunctionComponent<PosterProps> = ({ info, image, errorCode }) => {
  return (
    <Grid maxWidth="lg" container justifyContent="center" sx={{ m: "0 auto" }}>
      <Grid item xs={8}>
        <Card
          sx={{
            position: "relative",
            backgroundColor: "transparent",
          }}
        >
          <CardMedia component="img" image={image} sx={{ mt: 4 }} />
          <Stack
            sx={{
              position: { xs: "initial", md: "absolute" },
              bottom: "18px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "48px", md: "96px" },
                fontWeight: 500,
                textAlign: "center",
                color: colors.yellowDarkPrimary,
              }}
            >
              Error {errorCode}
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: 700,
                textAlign: "center",
                color: colors.whiteGrey,
              }}
            >
              {info}
            </Typography>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Poster;

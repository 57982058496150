import { Card, CardContent, CardHeader, Grid, styled } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { createOrderSell } from "../../api/AmtApi";
import useLoadWeb3 from "../../hooks/useLoadWeb3";
import { t } from "../../i18n";
import { colors } from "../../muiTheme";

import {
  addBankAccountState,
  loadingState,
  openQuickPayDialogState,
  toastContentState,
  toastStateState,
  userState,
} from "../../recoil/atom";
import { history } from "../../recoil/history";
import { ErrorTransfer, Merchant } from "../../typings/model";
import { OrderRequest } from "../../typings/model.api";
import { getErrorMessage, getStatusCode } from "../../util/common";
import { STATUS_CODE, TRADE_MODE } from "../../util/constants";
import { initTransferError } from "../../util/initial";
import DisclaimerDialog from "../DisclaimerDialog";
import { ActionButton } from "../RowMerchant";
import Transfer from "../Transfer";

const TransferCard = styled(Card)({
  background: colors.blueDarkPrimary,
  borderRadius: "20px",
  border: colors.boderYellow,
});

const TransferCardHeader = styled(CardHeader)({
  background: colors.whiteBackground,
  borderBottom: "none",
  color: colors.white,
  padding: "8px",
});

interface TransferSellProps {
  merchant: Merchant;
  isMobileSize?: boolean;
  onCancel?: () => void;
}

const TransferSell: FunctionComponent<TransferSellProps> = ({
  merchant,
  isMobileSize,
  onCancel,
}) => {
  const { checkConnection } = useLoadWeb3();

  const setLoading = useSetRecoilState(loadingState);
  const setIsToastAlertOpen = useSetRecoilState(toastStateState);
  const setToastContent = useSetRecoilState(toastContentState);
  const setOpenAddBankAccount = useSetRecoilState(addBankAccountState);

  const [payAmount, setPayAmount] = useState<number>();
  const [receiveAmount, setReceiveAmount] = useState<number>();
  const [openDisclaimerDialog, setOpenDisclaimerDialog] = useState(false);

  const [trasferError, setTransferError] =
    useState<ErrorTransfer>(initTransferError);

  const [openQuickPayDialog, setOpenQuickPayDialog] = useRecoilState(
    openQuickPayDialogState
  );

  const user = useRecoilValue(userState);

  const onAmountChange = (payAmount?: number, receiveAmount?: number) => {
    setPayAmount(payAmount);
    setReceiveAmount(receiveAmount);
  };

  const onTrade = async () => {
    if (validate()) {
      if (user) {
        if (!user.bank_account || !user.bank_code) {
          setOpenQuickPayDialog(false);
          setOpenAddBankAccount(true);
        } else {
          setOpenDisclaimerDialog(true);
        }
      } else {
        await checkConnection();
      }
    }
  };

  const validate = () => {
    let result = true;
    let {
      isPayAmountInvalid,
      isReceiveAmountInvalid,
      payAmountErrorText,
      receiveAmountErrorText,
    } = trasferError;

    if (!payAmount) {
      isPayAmountInvalid = true;
      payAmountErrorText = "This field is required.";
      result = false;
    } else {
      if (payAmount > merchant.available) {
        isPayAmountInvalid = true;
        payAmountErrorText = "More than available";
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = "More than available";
        result = false;
      }
    }

    if (!receiveAmount) {
      isReceiveAmountInvalid = true;
      receiveAmountErrorText = "This field is required.";
      result = false;
    } else {
      if (receiveAmount > merchant.maxAmount) {
        isPayAmountInvalid = true;
        payAmountErrorText = "Maximum Limit";
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = "Maximum Limit";
        result = false;
      }

      if (receiveAmount < merchant.minAmount) {
        isPayAmountInvalid = true;
        payAmountErrorText = "Minimum Limit";
        isReceiveAmountInvalid = true;
        receiveAmountErrorText = "Minimum Limit";
        result = false;
      }
    }

    setTransferError(
      result
        ? initTransferError
        : {
            isPayAmountInvalid,
            payAmountErrorText,
            isReceiveAmountInvalid,
            receiveAmountErrorText,
          }
    );
    return result;
  };

  const onCloseDisclaimerDialog = () => {
    setOpenDisclaimerDialog(false);
  };

  const onConfirmDisclaimerDialog = async (understood: boolean) => {
    if (understood) {
      const paramsReq: OrderRequest = {
        shop_id: merchant.merchantId,
        pay_amount: payAmount || 0,
      };

      try {
        setLoading(true);

        const response = await createOrderSell(paramsReq);
        const statusCode = getStatusCode(response);

        if (statusCode !== STATUS_CODE[20000]) throw response;

        const { order_id } = response.data.data;
        setLoading(false);

        history.push(`/order/${order_id}`);
      } catch (error) {
        setLoading(false);
        setToastContent(getErrorMessage(error));
        setIsToastAlertOpen(true);
      }
    }
    setOpenDisclaimerDialog(false);
  };

  return (
    <>
      <TransferCard variant="outlined" sx={{ mt: 1, mb: 3 }}>
        <TransferCardHeader
          title="Payment time limit"
          subheader="15 Minutes"
          sx={{ textAlign: "center" }}
          titleTypographyProps={{
            fontSize: "10px",
            letterSpacing: "0.4px",
            color: colors.whiteGrey,
          }}
          subheaderTypographyProps={{
            fontSize: "14px",
            letterSpacing: "0.17px",
            color: colors.white,
          }}
        />
        <CardContent sx={{ textAlign: "center", my: 2 }}>
          <Transfer
            fromLabel="You pay"
            fromCurrency={merchant.payCurrency.toLocaleUpperCase()}
            toLabel="You receive"
            toCurrency={merchant.receivedCurrency.toLocaleUpperCase()}
            merchant={merchant}
            mode={TRADE_MODE.SELL}
            error={trasferError}
            onAmountChange={onAmountChange}
          ></Transfer>

          <Grid container justifyContent="center" sx={{ pt: 3 }}>
            <Grid item md={10} lg={8} xl={6}>
              <ActionButton fullWidth disableRipple onClick={onTrade}>
                SELL {merchant.payCurrency.toLocaleUpperCase()}
              </ActionButton>
            </Grid>
          </Grid>
        </CardContent>
      </TransferCard>

      <DisclaimerDialog
        title={t.disclaimer.sell}
        open={openDisclaimerDialog}
        onClose={onCloseDisclaimerDialog}
        onConfirm={onConfirmDisclaimerDialog}
      ></DisclaimerDialog>
    </>
  );
};

export default TransferSell;

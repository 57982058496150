import { Dictionary } from "../typings/model";

export const PAYMENT_COUNTDOWN = 900;
export const WAIT_SELLTER_TRANSFER_COUNTDOWN = 300;
export const REFRESH_MERCHANT_COUNTDOWN = 15;
export const POLLING_ORDER_STATUS_SEC = 5;
export const PREFIX_LINE_URL = "https://line.me/R/ti/p/";

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

export enum CURRENCY {
  USDV = "USDV",
  THB = "THB",
}

export enum CURRENCY_DISPLAY {
  USDV = "USDV",
  THB = "THB",
}

export enum TRADE_MODE {
  BUY = "buy",
  SELL = "sell",
}

export const STATUS_CODE = {
  20000: "20000",
};

export const DESTINATION = {
  METAMASK: "metamask",
  HYBRID_DEX: "hybrid_dex",
};

export const DESTINATION_DESCRIPTION = [
  {
    partner_code: DESTINATION.METAMASK,
    display: "Metamask",
    chain: "Nova Chain",
  },
  {
    partner_code: DESTINATION.HYBRID_DEX,
    display: "Universe",
  },
];

export const ORDER_STATUS = {
  OPEN: "OPEN",
  CONFIRMED: "CONFIRMED",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  CANCELLED: "CANCELLED",
  AUTO_CANCELLED: "AUTO_CANCELLED",
};

export const ORDER_STATUS_ERROR_PAGE_LIST = [
  ORDER_STATUS.ERROR,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.AUTO_CANCELLED,
];

export const ORDER_STATUS_NO_POLLING_LIST = [
  ORDER_STATUS.COMPLETED,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.ERROR,
  ORDER_STATUS.AUTO_CANCELLED,
];

export const ORDER_STATUS_TITLE: Dictionary<string> = {
  ERROR: "ERROR PAYMENT",
  CANCELLED: "CANCEL PAYMENT",
  AUTO_CANCELLED: "AUTO CANCELLED PAYMENT",
};
